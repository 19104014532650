<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mdinForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <!--    <el-form ref="mdinForm" :model="mdinForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料图片" prop="imge_id">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mdinForm.imge_id" :imgeUrl="mdinForm.imge_url" :imgeUrlO="mdinForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具编号" prop="mdin_no">
                <el-input maxlength="30" v-model="mdinForm.mdin_no" placeholder="请填写模具编号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="中文品名" prop="mdin_name">
                <el-input maxlength="30" v-model="mdinForm.mdin_name" placeholder="请填写中文品名" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具属性" prop="mdin_attr">
                <el-input v-model="mdinForm.mdin_attr" maxlength="50" placeholder="请填写模具属性" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具类型" prop="mdin_type">
                <el-select filterable v-model="mdinForm.mdin_type" placeholder="请选择模具类型" size="small">
                  <el-option v-for="item in mouldTypeList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="我司货号" prop="prod_no">
                <el-input v-model="mdinForm.prod_no" maxlength="100" placeholder="请填写我司货号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具种类" prop="mdin_kind">
                <el-select filterable v-model="mdinForm.mdin_kind" placeholder="请选择模具种类" size="small">
                  <el-option v-for="item in mdinKindList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具部件" prop="mdin_part">
                <el-input v-model="mdinForm.mdin_part" maxlength="100" placeholder="请填写模具部件" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-input readonly @click.native="custImport(1)" v-model="mdinForm.cust_abbr" placeholder="请填写客户简称" show-word-limit>
                  <template slot="append">
                    <el-link type="primary">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            &lt;!&ndash; <el-col :md="12">
              <el-form-item label="最终客户" prop="cust_pabbr">
                <el-input readonly @click.native="custImport(2)"  v-model="mdinForm.cust_pabbr"  placeholder="请填写最终客户" show-word-limit>
                  <template slot="append">
                    <el-link type="primary">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col> &ndash;&gt;
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具存储地" prop="supp_name">
                <el-input v-model="mdinForm.supp_name" placeholder="请填写模具存储地" maxlength="30" show-word-limit> </el-input>
                &lt;!&ndash; <el-select
                  filterable
                  v-model="mdinForm.supp_id"
                  placeholder="请选择模具存储地"
                  size="small"
                >
                  <el-option
                    v-for="item in suppList"
                    :key="item.supp_id"
                    :label="item.supp_name"
                    :value="item.supp_id"
                  >
                  </el-option>
                </el-select> &ndash;&gt;
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具生产商" prop="mdin_producer">
                <el-select filterable v-model="mdinForm.mdin_producer" placeholder="请选择模具生产商" size="small">
                  <el-option v-for="item in mouldProdList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品材质编号" prop="prod_mtqa">
                <el-select filterable v-model="mdinForm.prod_mtqa" placeholder="请选择产品材质编号" size="small">
                  <el-option v-for="item in mouldTextList" :key="item.id" :label="item.label" :value="item.label"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具费" prop="mdin_cost">
                <el-input
                  v-model="mdinForm.mdin_cost"
                  @input="mdinForm.mdin_cost = helper.keepEngNum4(mdinForm.mdin_cost)"
                  @blur="mdinForm.mdin_cost = helper.calcPrice(mdinForm.mdin_cost, 4, 100000000)"
                  maxlength="13"
                  placeholder="请填写模具费"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="开模日期" prop="opmd_date">
                <el-date-picker v-model="mdinForm.opmd_date" type="date" placeholder="选择开模日期" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="外销员" prop="mdin_stff_name">
                <el-select filterable v-model="mdinForm.mdin_stff_name" placeholder="请选择外销员" size="small">
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="完成日期" prop="comp_date">
                <el-date-picker v-model="mdinForm.comp_date" type="date" placeholder="选择完成日期" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-dialog :title="CustTitle" :visible.sync="custFlag" width="70%">
        <MdinCust @custID="custID" @cancelCust="cancelCust"></MdinCust>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>-->
    <el-form ref="mdinForm" :model="mdinForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="模具图片" prop="imge_id">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mdinForm.imge_id" :imgeUrl="mdinForm.imge_url" :imgeUrlO="mdinForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-form-item label="是否模房" prop="mdin_attr" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
              <el-radio v-model="mdinForm.mdin_attr" :label="0">是</el-radio>
              <el-radio v-model="mdinForm.mdin_attr" :label="1">否</el-radio>
            </el-form-item>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具编号" prop="mdin_no">
                <el-input disabled maxlength="30" v-model="mdinForm.mdin_no" placeholder="自动生成" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具名称" prop="mdin_name" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input maxlength="20" v-model="mdinForm.mdin_name" placeholder="请填写模具名称" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具属性" prop="mdin_attr" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input v-model="mdinForm.mdin_attr" disabled maxlength="50" placeholder="请填写模具属性" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具类型" prop="mdin_type" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-select filterable v-model="mdinForm.mdin_type" placeholder="请选择模具类型" size="small">
                  <el-option v-for="item in mouldTypeList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>-->
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="客户简称" prop="cust_abbr" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input readonly @click.native="custImport(1)" v-model="mdinForm.cust_abbr" placeholder="请填写客户简称" show-word-limit>
                  <template slot="append">
                    <el-link type="primary">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="我司货号" prop="prod_no" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input v-model="mdinForm.prod_no" maxlength="100" placeholder="请填写我司货号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="12">
              <el-form-item label="模具种类" prop="mdin_kind">
                <el-select filterable v-model="mdinForm.mdin_kind" placeholder="请选择模具种类" size="small">
                  <el-option v-for="item in mdinKindList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="开模日期" prop="opmd_date" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-date-picker value-format="timestamp" v-model="mdinForm.opmd_date" type="date" placeholder="选择开模日期" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="外销员" prop="export_id" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-select filterable v-model="mdinForm.export_id" placeholder="请选择外销员" size="small">
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.user_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="完成日期" prop="comp_date" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-date-picker value-format="timestamp" v-model="mdinForm.comp_date" type="date" placeholder="选择完成日期" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="采购经办人" prop="purchase_id" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-select filterable v-model="mdinForm.purchase_id" placeholder="请选择采购经办人" size="small">
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.user_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="12">
              <el-form-item label="模具部件" prop="mdin_part">
                <el-input v-model="mdinForm.mdin_part" maxlength="100" placeholder="请填写模具部件" show-word-limit> </el-input>
              </el-form-item>
            </el-col>-->
            <!-- <el-col :md="12">
              <el-form-item label="最终客户" prop="cust_pabbr">
                <el-input readonly @click.native="custImport(2)"  v-model="mdinForm.cust_pabbr"  placeholder="请填写最终客户" show-word-limit>
                  <template slot="append">
                    <el-link :disabled="isShow" type="primary">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <!--          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具存储地" prop="supp_name">
                <el-input v-model="mdinForm.supp_name" placeholder="请填写模具存储地" maxlength="30" show-word-limit> </el-input>
                &lt;!&ndash; <el-select
                  filterable
                  v-model="mdinForm.supp_id"
                  placeholder="请选择模具存储地"
                  size="small"
                >
                  <el-option
                    v-for="item in suppList"
                    :key="item.supp_id"
                    :label="item.supp_name"
                    :value="item.supp_id"
                  >
                  </el-option>
                </el-select> &ndash;&gt;
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具生产商" prop="mdin_producer">
                <el-select filterable v-model="mdinForm.mdin_producer" placeholder="请选择模具生产商" size="small">
                  <el-option v-for="item in mouldProdList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="产品材质编号" prop="prod_mtqa">
                <el-select filterable v-model="mdinForm.prod_mtqa" placeholder="请选择产品材质编号" size="small">
                  <el-option v-for="item in mouldTextList" :key="item.id" :label="item.label" :value="item.label"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具费" prop="mdin_cost">
                <el-input
                  v-model="mdinForm.mdin_cost"
                  @input="mdinForm.mdin_cost = helper.keepEngNum4(mdinForm.mdin_cost)"
                  @blur="mdinForm.mdin_cost = helper.calcPrice(mdinForm.mdin_cost, 4, 100000000)"
                  maxlength="13"
                  placeholder="请填写模具费"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">-->
          <!--</el-row>-->
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="addSubTableRow">新增</el-button>
            <el-button type="danger" plain size="small" @click="delSubTableRow">删除</el-button>
            <el-button type="info" plain size="small" @click="copyRow">复制</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <mdopPartList
            :suppList="suppList"
            ref="subTable"
            :parentForm="mdinForm"
            @handleSelectionChange="handleSelectionChange"
          ></mdopPartList>
        </el-col>
      </el-row>
      <el-dialog :title="CustTitle" :visible.sync="custFlag" width="70%">
        <MdinCust @custID="custID" @cancelCust="cancelCust"></MdinCust>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { imgeAPI } from '@api/modules/imge';
import { mdinAPI } from '@api/modules/mdin';
import { custAPI } from '@api/modules/cust';
import { suppAPI } from '@api/modules/supp';
import { userAPI } from '@api/modules/user';
import addHeader from '@/views/component/addHeader';
import imgPopover from '@/views/component/imgPopover';
import inputUser from '@/views/component/inputUser';
import MdinCust from './Componet/MdinCust.vue';
import MdopPartList from '@/views/MouldManagement/MdinManage/TabChild/SubTable/MdopPartList.vue';
import { deleteTableRow } from '@assets/js/arrayUtils';

export default {
  name: 'mdinAddMain',
  components: {
    MdopPartList,
    addHeader,
    imgPopover,
    inputUser,
    MdinCust
  },
  data() {
    return {
      stffForm: {},
      mdinForm: {
        imge_id: 0,
        imge_url: null,
        imge_urlO: null,
        mdin_no: null,
        mdin_name: null,
        mdin_attr: null,
        mdin_type: null,
        prod_no: null,
        cust_id: null,
        cust_abbr: null,
        cust_pid: null,
        cust_pabbr: null,
        supp_id: null,
        mdin_producer: 0,
        prod_mtqa: null,
        mdin_cost: null,
        opmd_date: null,
        mdin_stff_name: null,
        comp_date: null,
        mdin_kind: null,
        moldinfoLists: [],
        purchase_id: Number(this.$cookies.get('userInfo').stff_id)
      },
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800
      },
      imgForm: {
        imge_id: 0,
        imge_url: null,
        imge_urlO: null
      },
      mouldTypeList: [
        { id: 0, label: '生产模' },
        { id: 1, label: '样品模' }
      ],
      custList: [],
      mouldProdList: [
        { id: 0, label: '模房' },
        { id: 1, label: '工厂' }
      ],
      mdinKindList: [
        { id: 0, label: '橡胶成型模' },
        { id: 1, label: '橡胶硫化模' },
        { id: 2, label: '注塑模' },
        { id: 3, label: '热合模' },
        { id: 4, label: '辅助工具' }
      ],
      mouldTextList: [
        { id: 1, label: '橡胶:01' },
        { id: 2, label: 'PA6:02' },
        { id: 3, label: 'TPR:03' },
        { id: 4, label: 'TPE:04' },
        { id: 5, label: 'TPU:05' },
        { id: 6, label: 'PP:06' },
        { id: 7, label: 'ABS:07' },
        { id: 8, label: 'PET:08' },
        { id: 9, label: '吹塑EVA:09' },
        { id: 10, label: '射出EVA:10' }
      ],
      stffType: [],
      suppList: [],
      CustTitle: '选择客户',
      custFlag: false,
      chooseIndex: 1,
      subTableMap: new Map()
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      this.getStffType();
      this.getCustList();
      this.getSuppList();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let mdinForm;
      // mdinForm = Object.assign(mdinForm,this.mdinForm);
      mdinForm = JSON.parse(JSON.stringify(this.mdinForm));
      mdinForm = Object.assign(mdinForm, staffForm);
      mdinForm.moldinfoLists.forEach(item => (item.mdnc_part_attr = item.mdnc_part_attr.join('/')));
      this.subTableMap = new Map();
      post(mdinAPI.addMdin, mdinForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/mdin_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
            this.resetForm('mdinForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mdinForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 关闭弹框
    cancelCust() {
      this.custFlag = false;
    },
    //打开客户弹窗
    custImport(val) {
      this.custFlag = true;
      this.chooseIndex = val;
    },
    custID(val) {
      if (this.chooseIndex === 1) {
        if (val.cust_id) {
          this.mdinForm.cust_id = val.cust_id;
          this.mdinForm.cust_abbr = val.cust_abbr;
        } else {
          this.mdinForm.cust_id = 0;
          this.mdinForm.cust_abbr = null;
        }
      } else if (this.chooseIndex === 2) {
        if (val.cust_id) {
          this.mdinForm.cust_pid = val.cust_id;
          this.mdinForm.cust_pabbr = val.cust_abbr;
        } else {
          this.mdinForm.cust_pid = 0;
          this.mdinForm.cust_pabbr = null;
        }
      }
      // if(val.cust_id){
      //   this.mdinForm.cust_id=val.cust_id;
      //   this.mdinForm.cust_abbr=val.cust_abbr;
      // }else{
      //   this.mdinForm.cust_id=0;
      //   this.mdinForm.cust_abbr=null;
      // }
      // if(val.cust_pid){
      //   this.mdinForm.cust_pid=val.cust_pid;
      //   this.mdinForm.cust_pabbr= val.pabbr;
      // }else{
      //   this.mdinForm.cust_pid=0;
      //   this.mdinForm.cust_pabbr= null;
      // }
      this.custFlag = false;
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.mdinForm.imge_id = res.data.imge_id;
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mdinForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mdinForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取客户简称
    getCustList() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取供应商
    getSuppList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mdinForm.imge_id = 0;
      this.mdinForm.imge_url = null;
      this.mdinForm.imge_urlO = null;
      this.mdinForm.moldinfoLists = [];
    },
    // 子表勾选值
    handleSelectionChange(row) {
      this.selectList = JSON.parse(JSON.stringify(row));
    },
    // 子表新增一行空数据并增加标识
    addSubTableRow() {
      this.mdinForm.moldinfoLists.push({
        key: this.mdinForm.moldinfoLists.length,
        mdnc_part_attr: [],
        mdin_money: 0
      });
      for (let i = 0; i < this.mdinForm.moldinfoLists.length; i++) {
        this.mdinForm.moldinfoLists[i].key = Number(i);
      }
    },
    delSubTableRow() {
      if (this.selectList.length === 0) return this.$message.warning('请至少选择一条数据!');
      let { tableData, map } = deleteTableRow('inquiryProId', this.mdinForm.moldinfoLists, this.subTableMap, this.selectList);
      this.mdinForm.moldinfoLists = tableData;
      this.subTableMap = map;
    },
    // 删除行
    deleteRow() {
      let temp = [];
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.mdinForm.moldinfoLists.length; j++) {
          if (this.selectList[i].key === this.mdinForm.moldinfoLists[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.mdinForm.moldinfoLists.splice(i, 1);
      }
      for (let i = 0; i < this.mdinForm.moldinfoLists.length; i++) {
        this.mdinForm.moldinfoLists[i].key = Number(i);
      }
    },
    copyRow() {
      this.selectList.forEach(item => (item.mu_mold_info_id = null));
      this.mdinForm.moldinfoLists = this.mdinForm.moldinfoLists.concat(this.selectList);
      this.selectList = [];
      this.$refs.subTable.$refs.multiTable.clearSelection();
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_solidDiv {
  border-top: #848484 dashed 1px;
  margin: 10px 0 20px 0;
}
.vd_vis {
  visibility: hidden;
}
.vd_dis {
  display: flex;
  justify-content: center;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
